<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import Tree from '@/components/Tree.vue'
import OrderEvents from '@/pages/Orders/components/OrderEvents.vue'
import ParcelTimeline from '../ParcelTimeline/index.vue'
import { getRegions } from '@/api/regions-api'

export default {
	name: 'OrdersOverview',
	components: { OrderEvents, Tree, ParcelTimeline },
	data() {
		return {
			regions: []
		}
	},
	async created() {
		const regionsData = await getRegions()
		this.regions = regionsData
	},
	computed: {
		...mapState('orders', ['orderInfo', 'clientName', 'deliveries']),
		...mapGetters('orders', ['formatDate', 'orderRef']),
		distance() {
			const distance = this.orderInfo.distance
			return distance ? `${distance} m` : 'N/A'
		},
		emptyExtras() {
			return _.every(this.orderInfo.extras, _.isEmpty)
		},
		emptyNotes() {
			return _.every(this.orderInfo.notes || [], _.isEmpty)
		},
		pickup() {
			return this.addressDetails(0)
		},
		delivery() {
			return this.addressDetails(1)
		},
		concatenatedRemarks() {
			const addresses = this.orderInfo.address || []
			const senderRemarks = addresses[0]?.remarks || ''
			const recipientRemarks = addresses[1]?.remarks || ''

			if (senderRemarks && recipientRemarks) {
				return `${senderRemarks} || ${recipientRemarks}`
			} else if (senderRemarks) {
				return `${senderRemarks} ||`
			} else if (recipientRemarks) {
				return `|| ${recipientRemarks}`
			} else {
				return ''
			}
		},
		currentCityCurrency() {
			const orderLocationShorthand = this.orderInfo.locationId?.split('_')[0] // eg. get HK from HK_HKG
			const currentCity = this.regions.find(
				(region) => region.id === orderLocationShorthand
			)
			return currentCity?.currency.standard_unit || ''
		},
		formattedPaymentAmount() {
			let amount = this.orderInfo.paymentAmount
			if (typeof amount === 'string') {
				amount = parseFloat(amount)
			}
			return amount ? amount.toFixed(2) : null
		},
	},
	methods: {
		...mapActions('orders', ['getReasons']),
		addressDetails(index) {
			const address = _.get(this, `orderInfo.address.${index}`, '')
			const contact = _.get(address, 'contacts.0')

			return {
				contactName: _.get(contact, 'name', 'unknown'),
				contactPhone: _.get(contact, 'phone', 'N/A'),
				address: _.get(address, 'text')
			}
		}
	}
}
</script>

<template>
	<div class="q-pa-md">
		<div class="row">
			<div class="col">
				<span>Customer Info ID: {{ orderInfo.clientId }}</span>
				<span>{{ clientName }} - {{ orderInfo.locationId }}</span>
			</div>
			<div class="col">
				<span>Picked up at {{ formatDate(orderInfo.pickupAt) }}</span>
				<span>Delivery by {{ formatDate(orderInfo.deliveryBy) }}</span>
			</div>
		</div>
		<div class="row">
			<div class="col-3">
				<p>
					<span>Sender: {{ pickup.contactName }}</span>
					<span>Phone number: {{ pickup.contactPhone }}</span>
				</p>

				<p>
					<span>Pickup District: {{ orderInfo.pickupDistrict }}</span>
					<span>Pickup Address: {{ pickup.address }}</span>
				</p>
			</div>
			<div class="col-2 text-center self-center">
				<span>Order Distance</span>
				<span>{{ distance }}</span>
				<span id="arrow" class="text-primary">⮕</span>
			</div>
			<div class="col-3 offset-1">
				<p>
					<span>Recipient: {{ delivery.contactName }}</span>
					<span>Phone number: {{ delivery.contactPhone }}</span>
				</p>

				<p>
					<span>
						Delivery District: {{ orderInfo.deliveryDistrict }}
					</span>
					<span>Delivery Address: {{ delivery.address }}</span>
				</p>
			</div>
		</div>

		<div class="row">
			<div class="col-9">
				<h5 class="text-weight-bold">Remarks</h5>
				<div v-if="this.orderInfo.orderDisplayId">
					<div v-if="concatenatedRemarks">
						{{ concatenatedRemarks }}
					</div>
					<div v-else>
						N/A
					</div>
				</div>
				<div v-else>
					<div v-for="(note, key) in orderInfo.notes || []" :key="key">
						{{ note }}
					</div>
					<div v-if="emptyNotes">N/A</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-9">
				<h5 class="text-weight-bold">Notes</h5>
				<div v-for="(note, key) in orderInfo.notes || []" :key="key">
					{{ note }}
				</div>
				<div v-if="emptyNotes">N/A</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<h5 class="text-weight-bold">Share link</h5>
				<a :href="orderInfo.shareLink" class="text-primary" target="_blank">
					{{ orderInfo.shareLink }}
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<h5 class="text-weight-bold">Payment Type</h5>
				<div v-if="orderInfo.paymentType">
					{{ orderInfo.paymentType }}
				</div>
				<div v-else>
					N/A
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<h5 class="text-weight-bold">Payment Amount</h5>
				<div v-if="formattedPaymentAmount">
					{{ formattedPaymentAmount }} {{ currentCityCurrency }}
				</div>
				<div v-else>
					N/A
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<h5 class="text-weight-bold">Extras</h5>
				<tree v-for="(extra, key) in orderInfo.extras" :key="key" :nodes="extra" />
				<div v-if="emptyExtras">N/A</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<order-events />
			</div>
		</div>
		<div class="row">
			<div class="col-9">
				<h5>
					Parcel no.: {{ orderInfo.parcelNum }} (Total Weight:
					{{ orderInfo.totalWeight }} kg / Size: {{ orderInfo.size }})
				</h5>
				<hr />
				<parcel-timeline :deliveries="deliveries" />
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
span {
	display: block;
}

.row+.row {
	margin-top: 3rem;
}

#arrow {
	font-size: 60px;
	line-height: 5rem;
}
</style>
